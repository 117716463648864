.feature_page {
  & > .main_box {
    width: 100vw;
    max-width: 480px;
    margin: 0 auto 50px;
    padding: 0 20px;
    & > .title_box {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      line-height: 80px;
    }
    & > .list_box {
      padding-left: 20px;
      text-align: justify;
      & > .item_box {
        color: #333333;
        margin-bottom: 15px;
        font-size: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
