* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a,
a:hover,
a:visited {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: transparent;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
