.privacy_policy_page {
  padding: 0 30px;
  font-size: 14px;
  color: #303133;
  line-height: 1.5;
  & > .title_box {
    font-size: 24px;
    font-weight: bold;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > .main_box {
    .text_box {
      margin-bottom: 15px;
      .title {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  & > .tips_box {
    font-size: 13px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
