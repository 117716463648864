.download_page {
  & > .main_box {
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > .logo_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10vh;
      & > .img_box {
        width: 80px;
        border-radius: 15px;
        overflow: hidden;
      }
      & > .text_box {
        font-size: 14px;
        letter-spacing: 5px;
        font-weight: bold;
        margin-top: 20px;
        margin-left: 10px;
      }
    }
    & > .btn_box {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 300px;
      height: 48px;
      border-radius: 24px;
      font-size: 16px;
      font-weight: 500;
      border: 1px solid #0086ff;
      color: #0086ff;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &.active {
        background-color: #0086ff;
        color: #ffffff;
        margin-bottom: 20px;
      }
      &.not {
        opacity: 0.5;
      }
    }
  }
  & > .footer_box {
    position: fixed;
    bottom: 0;
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
    width: 100vw;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 1;
    & > .text_box {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      &:last-child {
        margin-bottom: 0;
      }
      .nav_box {
        color: #3d8af5;
        padding: 0 10px;
        &:nth-child(2) {
          border: 1px solid rgba(0, 0, 0, 0.45);
          border-top: none;
          border-bottom: none;
        }
      }
    }
  }
  & > .tips_box {
    background-color: #333333;
    color: #ffffff;
    font-size: 16px;
    position: fixed;
    top: calc((100vh - 40px) / 2);
    left: calc((100vw - 260px) / 2);
    width: 260px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    letter-spacing: 2px;
    opacity: 0;
    transform: scale(0);
    &.fadeIn {
      animation: fadeIn 3s ease-in-out forwards;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}
