.home_page {
  & > .bg_box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background: linear-gradient(45deg, #00eaff, #0086ff);
  }
  & > .logo_box {
    position: relative;
    z-index: 2;
    margin: 50px 0 0 50px;
    display: flex;
    align-items: center;
    .img_box {
      width: 32px;
      margin-right: 10px;
    }
    .text_box {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      line-height: 1;
    }
  }
  & > .main_box {
    position: relative;
    z-index: 2;
    width: 750px;
    height: 600px;
    margin: 80px auto 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .phone_box {
      width: 260px;
      position: relative;
      & > .img_box {
        position: relative;
        z-index: 2;
      }
      & > .swiper_list {
        position: absolute;
        width: calc(100% - 36px);
        z-index: 1;
        top: 37px;
        left: 18px;
      }
    }
    & > .text_box {
      & > .title_box {
        font-size: 48px;
        font-weight: bold;
        color: #ffffff;
      }
      & > .text_box {
        font-size: 20px;
        color: #ffffff;
        line-height: 1.5;
        margin: 100px 0 50px 0;
      }
      & > .btn_box {
        display: flex;
        & > .btn_list {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 20px;
          .btn_item {
            width: 160px;
            border-radius: 10px;
            display: block;
            overflow: hidden;
          }
        }
        & > .code_box {
          width: 120px;
          padding: 10px;
          background-color: #ffffff;
          border-radius: 10px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          & > .text_box {
            font-size: 12px;
            color: #999999;
            margin-top: 5px;
          }
        }
      }
    }
  }
  & > .tips_box {
    position: relative;
    z-index: 2;
    text-align: center;
    font-size: 13px;
    color: #ffffff;
  }
}
