.notfound_page {
  color: #303133;
  text-align: center;
  .title {
    font-size: 120px;
    font-weight: bold;
    letter-spacing: 10px;
    margin: 100px 0 10px;
  }
  .text {
    font-size: 18px;
  }
}
